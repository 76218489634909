export default {
    getLang(defaultLang) {
        if (localStorage.getItem('lang')) {
            return localStorage.getItem('lang')
        } else {
            const localLang = navigator.language || navigator.userLanguage;

            switch (localLang.substring(0, 2)) {
                case 'zh':
                    localStorage.setItem('lang', 'zh_cn')
                    return 'zh_cn'
                case 'hk':
                    localStorage.setItem('lang', 'hk')
                    return 'hk'
                case 'en':
                    localStorage.setItem('lang', 'en')
                    return 'en'
                default:
                    localStorage.setItem('lang', defaultLang);
                    return defaultLang
            }
        }
    }
}
