export default [{
    name: 'MIVisitor',
    path: '/MIVisitor',
    meta: {requireAuth: true, roles: ['SA', 'AD_MIV']},
    component: () => import('@/views/MIVisitor/mainPage.vue'),
    redirect: "/MIVisitor/dataparsing",
    children: [{
        path: '/MIVisitor/dataparsing',
        meta: {requireAuth: true, roles: ['SA', 'AD_MIV']},
        component: () => import('@/views/MIVisitor/dataParsing.vue'),
    }]
}];