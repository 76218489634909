import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import routerMetadata from './routerMetadata.js';
import routerMicroinverter from './routerMicroinverter.js';
import routerPLCController from './routerPLCController.js';
import routerGatewayVisitor from './routerGatewayVisitor.js';
import routerMIVisitor from './routerMIVisitor.js';
import jwtDecode from 'jwt-decode';

Vue.use(VueRouter);

//解决在当前页面点击当前页面链接报错的问题
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
}
const routes = [
    {
        path: '/',
        redirect: '/login',
    },
    {
        path: '/login',
        component: () => import('@/views/login.vue')
    },
    ...routerMetadata,
    ...routerGatewayVisitor,
    ...routerMIVisitor,
    ...routerMicroinverter,
    ...routerPLCController,
    {
        path: "*",
        redirect: "/404",
    },
    {
        path: "/404",
        component: resolve => require.ensure([], () => resolve(require('@/views/notFound.vue')), 'forgot'),
    },
    {
        path: "/lackPermissions",
        component: resolve => require.ensure([], () => resolve(require('@/views/lackPermissions.vue'))),
    }
];

const router = new VueRouter({routes});

sessionStorage.getItem('token') ? store.commit('set_token', {token: sessionStorage.getItem('token'), status: true}) : null;
store.commit('set_serverKey', sessionStorage.getItem('serverKey'));
store.commit('set_key', {pkcsType: 'pkcs8', pkcsSize: '1024'});

router.beforeEach((to, from, next) => {
    store.commit('CLAER_CANCEL');
    if (to.matched.some(record => record.meta.requireAuth)) {
        if (store.state.token.token) {
            let roles = to.meta.roles
            if (roles) {
                let flag = false;
                let types = jwtDecode(store.state.token.token).userType;
                for (let i = 0; i < types.length; i++) {
                    if (roles.includes(types[i])) {
                        flag = true;
                        break;
                    }
                }
                if (flag) {
                    next();
                } else {
                    next({path: "/lackPermissions"});
                }
            } else {
                next({path: "/404"});
            }
        } else {
            next({path: '/login', query: {redirect: to.fullPath}});
        }
    } else {
        next();
    }
});

export default router;
