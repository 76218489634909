<template>
</template>
<script type="application/javascript">
export default {
    data() {
        return {}
    },
    mounted() {
        console.log("%cSTOP!", "font-size:45px;color:red;font-weight:bold;");
        console.log(`%c${this.$t('F12')}`, "font-size:25px;color:#ff9800;font-weight:bold;");
    }
}
</script>
<style scoped lang="scss">
</style>
